<template src="./filooCard.html"></template>
<script>

import mixins from "@/mixins/mixins.js";
import AppCarousel from "vue-owl-carousel";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";

export default {
  mixins: [mixins],

  components: {
    AppCarousel,
  },

  data() {
    const desktopConfig = {
      stagePadding: 0,
      startPosition: 0,
      dots: false,
      nav: true,
    };

    return {
      navText: ["<", ">"],
      filooCard: undefined || [],
      customerData: {},
      isDasa: undefined || false,
      customerPlan: {},
      responsiveOptions: {
        1600: {
          ...desktopConfig,
          margin: 2,
          items: 1,
          stagePadding: 0,
        },
        1200: {
          ...desktopConfig,
          items: 1,
          startPosition: 0,
          stagePadding: 0,
        },
        900: {
          ...desktopConfig,
          items: 1,
        },
        700: {
          items: 1,
          stagePadding: 0,
        },
        400: {
          items: 1,
          stagePadding: 0,
          margin: 0,
        },
        300: {
          items: 1,
          stagePadding: 0,
          margin: 0,
        },
        0: {
          items: 1,
        },
      },
    };
  },

  methods: {
    formatName(fullName) {
      const fullNameSplitted = fullName.split(" ");
      const firstName = fullNameSplitted[0];
      const lastName = fullNameSplitted[fullNameSplitted.length - 1];

      if (fullNameSplitted.length > 2) {
        let surname = "";
        for (var i = 1; i < (fullNameSplitted.length - 1); i++) {
          surname += " " + fullNameSplitted[i].charAt(0).toUpperCase() + ". ";
        }

        return firstName.toUpperCase() + " " + surname + " " + lastName.toUpperCase();
      }

      return firstName.toUpperCase() + " " + lastName.toUpperCase();
    },

    formatCardNumber(cardNumber) {
      return cardNumber.replace(/^(\d{4})\s?\s?(\d{4})\s?\s?(\d{4})\s?\s?(\d{4})$/, "$1 $2 $3 $4");
    },

    isBasicPlan() {
      return this.customerPlan && this.customerPlan.name.toLowerCase().includes("basico");
    },

    isPlusPlan() {
      return this.customerPlan && this.customerPlan.name.toLowerCase().includes("plus");
    },

    init() {
      this.customerData = this.$session.get("customerData");
      this.customerPlan = this.$session.get("customerPlan");
      this.axios
        .get(
          this.$store.state.filooServer +
          "patient/patient-dependents/" +
          this.customerData.patientId,
          {
            headers: { Authorization: this.$session.get("bearer") },
          }
        )
        .then(({ status, data: patients }) => {
          this.isDasa = patients.some(value => value.isDasa === true);
          if (status == 200 && patients.length > 0) {
            for (const p in patients) {
              patients[p].plan = this.customerPlan.name;
              patients[p].patientCardNumber = this.formatCardNumber(patients[p].patientCardNumber);
              patients[p].name = this.formatName(patients[p].name);
              this.filooCard.push(patients[p]);
            }
          }
        }).catch(function (error) {
          console.log(error);
        });
    },
    downloadCard() {
      const node = document.querySelector(".owl-item.active");

      domtoimage.toBlob(node)
        .then((blob) => {
          saveAs(blob, "carta-dasa-saude.png");
        });
    },
  },
  mounted: function () {
    this.init();
  },
};
</script>

<style lang="scss" src="./filooCard.scss"></style>
